<template>
  <div>
    <div class="big-head">
      <span>#{{ $route.params.order }}</span>
      
      <v-flex grow>
        <h1>
          {{ $route.params.name }}
          <em></em>
        </h1>
      </v-flex>
      
      <v-flex shrink>
        <InputSelect
          ref="companyGroupListInput"
          textLabel
          v-model="paymentMethod"
          :items="paymentMethods"
          unselectedText="Todos"
          unselectedValue="0"
          valueAttribute="id"
          textAttribute="name"
          placeholder="Meio de Pagto"
        ></InputSelect>
      </v-flex>
      
      <v-flex shrink>
        <InputSelect
          class="ml-3"
          :items="[
            { id: 10, name: 10 },
            { id: 20, name: 20 },
            { id: 50, name: 50 },
            { id: 100, name: 100 },
          ]"
          valueAttribute="id"
          textAttribute="name"
          v-model="pageSize"
        />
      </v-flex>
    </div>
    <v-flex shrink>
          <v-btn
            fab
            flat
            small
            color="white"
            class="btn-default_blue-light mr-2"
            @click="downloadExcel()"
          >
            <faIcon type="fas" name="search" size="16px" />
          </v-btn>
        </v-flex>
  </div>
</template>
<script type="plain/text">
import { EntityType } from "../../../scripts/models/enums/entityType.enum.js";
import PaymentMethod from "../../../scripts/models/enums/paymentMethod.enum";
import { CashFlowPages } from "../../../scripts/models/enums/cashFlowPages.enum";
import { StatusTransferResource } from "../../../scripts/models/enums/statusTransferResource.enum";
import DonationConciliationStatus from "../../../scripts/models/enums/donationConciliationStatus.enum";
import ReportService from "../../../scripts/services/report.service";
import {
  CashFlowFilters
} from "../../../scripts/models/cashFlow.model";

export default {
  components: { },
  props: {
    pageOptions: null,
    entity: null,
  },
  methods: {
    downloadExcel() {
      this.reportService.cashFlowReport(this.cashFlowFilters).then((response) =>{
        if (response != null) {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/excel" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Relatório_Fluxo_Financeiro_Conciliado" + this.$moment().format("YYYY-MM-DD_H:m") + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        }
      })
      
      var props = this.mountProps();
      var data = this.mountDataToExport();
      var excel = new Excel(props);
      excel.setData(data);
      excel.setHeader(this.getExportHeaderBasedOnPageNumber());
      var blob = excel.exportToBlob();
      this.export(blob, "xlsx", "BaseAConciliar");
    }
  },
  watch: {
    paymentMethod: {
      immediate: true,
      handler(newVal, oldVal) {
        this.cashFlowFilters.paymentMethod = this.paymentMethod;
      }
    }
  },
  data() {
    return {
      paymentMethods: [
        { id: PaymentMethod.PayrollDeduction, name: "Débito em Folha" },
        { id: PaymentMethod.Ticket, name: "Boleto" },
        { id: PaymentMethod.CreditCard, name: "Cartão de Crédito" }
      ],
      paymentMethod: 0,
      statusConciliation: DonationConciliationStatus.ConciliedByAuc,
      cashFlowPagesEnum: "",
      cashFlowFilters: {},
      reportService: new ReportService(),
      entityTypeEnum: null,
      pageSize: 10
    };
  },
  created() {
    this.cashFlowPagesEnum = CashFlowPages;
    this.statusTransferResource = StatusTransferResource;
    this.entityTypeEnum = EntityType;
    this.pageNumber = CashFlowPages.Concilied;
    this.$emit("pageActive", CashFlowPages.Concilied);
    
    this.cashFlowFilters = new CashFlowFilters();
    this.reportService = new ReportService();
    
    this.cashFlowFilters.paymentMethod = 0;
    this.cashFlowFilters.paginate = false;
    this.cashFlowFilters.entityId = this.$route.query.entityId;
    this.cashFlowFilters.statusConciliation = this.statusConciliation;
    this.cashFlowFilters.statusTransferResource = this.statusTransferResource.ConciliedByInstitution;
  },
};
</script>
